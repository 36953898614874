import { Injectable, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';

@Injectable({
  providedIn: 'root',
})
export class EventEmitterService {

  onHealthKitSyncComplete = new EventEmitter();
  healthKitSyncCompleteSub: Subscription;

  onRaniEnabled = new EventEmitter();
  raniEnabledSub: Subscription;

  constructor() {}

  healthKitSyncComplete() {
    this.onHealthKitSyncComplete.emit();
  }

  raniEnabled() {
    this.onRaniEnabled.emit();
  }
}
