<ion-app *ngIf="this.internetConnectionFlag===true">
    <ion-menu side="start" menuId="m1" contentId="main"  *ngIf="!restrictTab && loggedUserType==='Provider' || loggedUserType==='ProviderStaff'||loggedUserType==='Admin' || loggedUserType==='Staff' || loggedUserType==='Patient'">
      <ion-content>
        <div class="container">
          <div class="overlay">
            <ion-avatar>
              <ion-menu-toggle>
                <img class="img" src="{{profilePhotoUrl}}" style="height:100px;width:100px;" *ngIf="profilePhotoUrl!==''">
                <img src="https://i.ibb.co/SXkLNNt/Deafult-Profile.png" style="height:100px;width:100px;" *ngIf="profilePhotoUrl===''">
              </ion-menu-toggle>
              <p class="ion-text-end" slot="end">
                <ion-icon color="primary" name="create-outline"  menuClose="left"></ion-icon>
              </p>

            </ion-avatar>
          </div>
        </div>
        <ion-card>
          <ion-card-content>
            <div class="ion-item">
              <ion-item class="ion-item" lines="none" style="margin-top: -10px;">
                <ion-label ><strong>{{this.user.name}}</strong></ion-label>
              </ion-item>
            </div>
          </ion-card-content>
        </ion-card>
      <div class="nav-links">
        <ion-menu-toggle *ngIf="loggedUserType==='Patient'">
          <p routerLink="/my-account/patient" [queryParams]="{type:'Profile'}"><img style="height:26px" src="/assets/profile-icon.png" ><strong style="margin-left: 8%;">Profile</strong></p>
        </ion-menu-toggle>
        <ion-menu-toggle *ngIf="loggedUserType==='Patient'">
          <p routerLink="/my-account/patient" [queryParams]="{type:'medicalProfile'}"><img style="height:26px;width:23px;margin-left:3px" src="/assets/medical-records.svg" ><strong style="margin-left: 8%;">Medical Profile</strong></p>
        </ion-menu-toggle>
        <ion-menu-toggle *ngIf="(loggedUserType==='Provider' ||loggedUserType==='ProviderStaff') ">
          <p routerLink="/my-account/provider"><img style="height:26px" src="/assets/profile-icon.png" ><strong style="margin-left: 8%;">Profile</strong></p>
        </ion-menu-toggle>
        <ion-menu-toggle *ngIf="loggedUserType==='Staff' || loggedUserType==='Admin'">
          <p routerLink="/category1-staff" [queryParams]="{type:'Provider'}"><img style="height:25px" src="/assets/Onboard.svg" ><strong style="margin-left: 10%;">Onboard Provider</strong></p>
        </ion-menu-toggle>
        <ion-menu-toggle *ngIf="loggedUserType==='Provider' || loggedUserType==='Staff' ||  loggedUserType==='Admin' || loggedUserType==='ProviderStaff'">
            <p routerLink="/category1-provider"><img style="height:25px" src="/assets/Onboard.svg" ><strong style="margin-left: 10%;">Onboard Patient</strong></p>
        </ion-menu-toggle>
        <ion-menu-toggle *ngIf="loggedUserType==='Admin'">
          <p routerLink="/category1-admin"><img style="height:25px" src="/assets/Onboard.svg" ><strong style="margin-left: 10%;">Onboard Staff</strong></p>
      </ion-menu-toggle>
      <ion-menu-toggle *ngIf=" loggedUserType==='Staff' ||  loggedUserType==='Admin'">
            <p routerLink="/category1-staff" [queryParams]="{type:'ProviderStaff'}"><img style="height:25px" src="/assets/Onboard.svg" ><strong style="margin-left: 10%;">Onboard Provider Staff</strong></p>
      </ion-menu-toggle>
      <ion-menu-toggle *ngIf="loggedUserType==='Provider' && isProvider===true" >
        <p routerLink="/category1-staff" [queryParams]="{type:'ProviderStaff'}"><img style="height:25px" src="/assets/Onboard.svg" ><strong style="margin-left: 10%;">Onboard Staff</strong></p>
      </ion-menu-toggle>
      <ion-menu-toggle *ngIf="loggedUserType==='Provider' || loggedUserType==='ProviderStaff'">
        <p routerLink="question-generate/questionnaires"><img style="height:30px;width:30px" src="/assets/Surveyor.svg"  ><strong style="margin-left: 7%;">Questionnaire List</strong></p>
      </ion-menu-toggle>
      <ion-menu-toggle *ngIf="loggedUserType !== 'Patient'">
        <p routerLink="procedures"><img style="height:30px;width:30px" src="/assets/Surveyor.svg"  ><strong style="margin-left: 7%;">Procedures</strong></p>
      </ion-menu-toggle>
      <ion-menu-toggle *ngIf="loggedUserType==='Staff' || loggedUserType==='Admin'">
        <p routerLink="question-generate"><img style="height:30px;width:30px" src="/assets/Surveyor.svg"  ><strong style="margin-left: 7%;">Questionnaire</strong></p>
      </ion-menu-toggle>
      <ion-menu-toggle *ngIf="(loggedUserType==='Provider' || loggedUserType==='ProviderStaff'|| loggedUserType==='Patient' || loggedUserType==='Staff')">
        <p routerLink="contact-us" ><img style="height:40px;width: 27px;" src="/assets/contact.svg"  ><strong style="margin-left: 8%;">Contact Us</strong></p>
      </ion-menu-toggle>
      <ion-menu-toggle *ngIf="(loggedUserType==='Provider' || loggedUserType==='ProviderStaff'|| loggedUserType==='Patient' || loggedUserType==='Staff')">
        <p routerLink="about-us" ><img style="height:25px;width: 25px; " src="/assets/About.svg"  ><strong style="margin-left: 10%;">About Us</strong></p>
      </ion-menu-toggle>
      <ion-menu-toggle *ngIf="loggedUserType==='Patient'">
        <p routerLink="ihealth-auth"><img style="height:25px;width: 25px;" src="/assets/medical-records.svg"><strong style="margin-left: 10%;">Connect iHealth</strong></p>
      </ion-menu-toggle>
      <ion-menu-toggle *ngIf="loggedUserType==='Provider' || loggedUserType==='ProviderStaff'|| loggedUserType==='Patient' || loggedUserType==='Staff' || this.loggedUserType==='Admin'">
        <p (click)="logOut()"><img style="height:20px;width: 25px;" src="/assets/Sign Out.svg"  ><strong style="margin-left: 10%;">Sign Out</strong></p>
      </ion-menu-toggle>
      </div>
      </ion-content>
    </ion-menu>
    <ion-router-outlet id='main'></ion-router-outlet>
    <ion-tabs  >
      <ion-tab-bar slot="bottom" *ngIf="loggedUserType!==null">
        <ion-tab-button tab="dashboard" *ngIf="loggedUserType==='Patient'">
          <img style="height:25px" src="/assets/Home.svg" height="8px" >
        </ion-tab-button>
        <ion-tab-button tab="provider-dashboard" *ngIf="loggedUserType!=='Patient'">
          <img style="height:25px" src="/assets/Home.svg" height="8px" >
        </ion-tab-button>
        <ion-tab-button tab="browse" *ngIf="loggedUserType==='Patient'">
          <img style="height:25px;" src="/assets/Dashboard.svg" height="8px">
        </ion-tab-button>
        <ion-tab-button tab="health-records" *ngIf="loggedUserType==='Patient'">
          <img style="height:25px" src="/assets/UploadMedicalRecords.svg" height="8px">
          <ion-badge  color="danger" *ngIf="questionnairesLength>0">{{questionnairesLength}}</ion-badge>
        </ion-tab-button>
        <ion-tab-button tab="chat" *ngIf="isRaniEnabled || loggedUserType === 'Staff'">
          <img style="height:28px" src="/assets/bot.svg" height="9px">
        </ion-tab-button>
        <ion-tab-button  tab="patients-records" *ngIf="(loggedUserType==='Provider'||loggedUserType==='ProviderStaff') ">
          <img style="height:25px" src="/assets/UploadMedicalRecords.svg" height="8px">
          <ion-badge  color="danger" *ngIf="this.patientIds.length>0">{{this.patientIds.length}}</ion-badge>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>

  </ion-app>
  <ion-app *ngIf="this.internetConnectionFlag===false" style="background-color: white;">
    <ion-slide>
      <ion-text >
        <img src="/assets/internet.png" style="margin-top: -153px;">
        <h5 style="margin-top:40px;height:100px">Oops, No Internet Connection</h5>
        <h6 style="color: darkgrey;">Make sure wifi or cellular data is turned on</h6>
        <ion-icon name="refresh-circle-outline" style="margin-top: 20px;zoom:2;" (click)="reloadApp()"></ion-icon>
      </ion-text>
    </ion-slide>
  </ion-app>
