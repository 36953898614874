import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { Storage } from '@ionic/storage';
import { HOST_NAME } from 'src/environments/environment.local';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  signUpData;
  loggedFlag;
  constructor(private http: HttpClient) {}
  postSignUp(signUpData) {
    return this.http.post(HOST_NAME + '/user_check/user_detail/', signUpData);
  }
  getSignUp() {
    return this.signUpData;
  }
  postLoggedFlag(data) {
    this.loggedFlag = data;
  }
  patchLoggedProvider(data) {
    localStorage.setItem('user_provider', data);
  }
  onLogin(login) {
    return this.http.post(HOST_NAME + '/user_check/user_detail/auth/', login);
  }
  getLoggedFlag() {
    try {
      return localStorage.getItem('user_email');
    } catch {}
  }
  getLoggeduserType() {
    try {
      return localStorage.getItem('user_type');
    } catch {}
  }
  getLoggedProvider() {
    try {
      return localStorage.getItem('user_provider');
    } catch {}
  }

  getProviderId(id) {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${localStorage.getItem('token')}`);

    return this.http.get(HOST_NAME + '/provider/' + id, { headers });
  }
  listProviderId() {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${localStorage.getItem('token')}`);
    return this.http.get(HOST_NAME + '/provider/', { headers });
  }
  getPatients() {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${localStorage.getItem('token')}`);
    return this.http.get(HOST_NAME + '/patient/', { headers });
  }
  patchPatient(id, data) {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${localStorage.getItem('token')}`);
    return this.http.put(HOST_NAME + '/patient/' + id, data, { headers });
  }
  getAccessToken() {
    return localStorage.getItem('token');
  }
  onBoardAuth(onboardForm) {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${localStorage.getItem('token')}`);
    return this.http.post(HOST_NAME + '/user_check/onboard_check/', onboardForm, { headers });
  }
  forgotPassword(profileForm) {
    return this.http.post(HOST_NAME + '/user_check/reset_password/', profileForm);
  }
  verifyEmail(profileForm) {
    return this.http.post(HOST_NAME + '/user_check/user_detail/email_verify', profileForm);
  }
  otpSubmit(otpForm) {
    return this.http.post(HOST_NAME + '/user_check/otp_check/', otpForm);
  }
  updatePassword(loginForm) {
    return this.http.post(HOST_NAME + '/user_check/update_password/', loginForm);
  }
}
