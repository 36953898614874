import { LogLevel, Configuration} from '@azure/msal-browser';
import { REDIRECT_URL } from 'src/environments/environment.local';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
class AppConfig{
  constructor(){}

}
export const b2cPolicies = {
  names: {
      signIn: 'B2C_1_signinsignup1',
      signUp: 'B2C_1_P_Signup'
  },
  authorities: {
      signIn: {
       authority: 'https://wellcoursellc.b2clogin.com/wellcoursellc.onmicrosoft.com/B2C_1_SISO',
      },
      signUp: {
        authority: 'https://wellcoursellc.b2clogin.com/wellcoursellc.onmicrosoft.com/B2C_1_P_Signup',
      }

  },
  authorityDomain: 'wellcoursellc.b2clogin.com'
};
export const msalConfig: Configuration = {
     auth: {
         clientId: 'bc800967-2d5b-4d1c-9182-f9b566a7c554',
         authority: b2cPolicies.authorities.signIn.authority,
         knownAuthorities: [b2cPolicies.authorityDomain],
        redirectUri: REDIRECT_URL
     },
     cache: {
        //  cacheLocation: BrowserCacheLocation.LocalStorage,
        cacheLocation:'localStorage',
        storeAuthStateInCookie: false,
     },
     system: {
         loggerOptions: {
            loggerCallback: (logLevel, message, containsPii) => {
             },
             logLevel: LogLevel.Verbose,
             piiLoggingEnabled: false,
         }
     }
 };
 export const protectedResources = {
    todoListApi: {
      endpoint: 'https://graph.microsoft.com',
      scopes: ['https://wellcoursellc.onmicrosoft.com/bc800967-2d5b-4d1c-9182-f9b566a7c554/offline_access',
               'https://wellcoursellc.onmicrosoft.com/bc800967-2d5b-4d1c-9182-f9b566a7c554/access_as_user',
               'https://wellcoursellc.onmicrosoft.com/bc800967-2d5b-4d1c-9182-f9b566a7c554/tasks.read',
               'https://wellcoursellc.onmicrosoft.com/bc800967-2d5b-4d1c-9182-f9b566a7c554/tasks.write'],
    },
  };
  export const loginRequest = {
    scopes: ['']
  };
