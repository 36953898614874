/* eslint-disable no-underscore-dangle */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { Observable, Subject } from 'rxjs';
import { HOST_NAME } from 'src/environments/environment.local';
@Injectable({
  providedIn: 'root'
})
export class SharedQuestionsService {
  surveyResponse;
  questions=[];
  quesQuesOptions;
  private _listeners = new Subject<any>();
  constructor(private http: HttpClient,private storage: Storage) { }
  postQuestions(questions)
  {
    const headers = new HttpHeaders().set(
      'Authorization',
      `Bearer ${localStorage.getItem('token')}`
    );
    return this.http.post(HOST_NAME+'/questionnaire/',questions,{headers});
  }
  getQuestionId(id)
  {
    const headers = new HttpHeaders().set(
      'Authorization',
      `Bearer ${localStorage.getItem('token')}`
    );
    return this.http.get(HOST_NAME+'/questionnaire/'+id,{headers});
  }
  getQuestions()
  {
    const headers = new HttpHeaders().set(
      'Authorization',
      `Bearer ${localStorage.getItem('token')}`
    );
    return this.http.get(HOST_NAME+'/questionnaire/',{headers});
  }
  setQuestions()
  {
    const headers = new HttpHeaders().set(
      'Authorization',
      `Bearer ${localStorage.getItem('token')}`
    );
    this.http.get(HOST_NAME+'/questionnaire/',{headers}).subscribe(questionnaires=>{
      this.storage.set('questionnaires',questionnaires);
    },error=>{});
  }
  getSurveys()
  {
    const headers = new HttpHeaders().set(
      'Authorization',
      `Bearer ${localStorage.getItem('token')}`
    );
    return this.http.get(HOST_NAME+'/survey/',{headers});
  }
  setSurveys()
  {
    const headers = new HttpHeaders().set(
      'Authorization',
      `Bearer ${localStorage.getItem('token')}`
    );
    this.http.get(HOST_NAME+'/survey/',{headers}).subscribe(surveys=>{
      this.storage.set('surveys',surveys);
    });
  }
  patchQuestion(id,form)
  {

    const headers = new HttpHeaders().set(
      'Authorization',
      `Bearer ${localStorage.getItem('token')}`
    );
    return this.http.put(HOST_NAME+'/questionnaire/'+id,form,{headers});
  }
  deleteQuestion(id)
  {
    const headers = new HttpHeaders().set(
      'Authorization',
      `Bearer ${localStorage.getItem('token')}`
    );
    return this.http.delete(HOST_NAME+'/questionnaire/'+id,{headers});
  }
  postSurvey(surveyQuestions)
  {
    const headers = new HttpHeaders().set(
      'Authorization',
      `Bearer ${localStorage.getItem('token')}`
    );
    this.surveyResponse = surveyQuestions;

    return this.http.post(HOST_NAME+'/survey/',surveyQuestions,{headers});

  }
  patchSurvey(id,data)
  {
    const headers = new HttpHeaders().set(
      'Authorization',
      `Bearer ${localStorage.getItem('token')}`
    );
    return this.http.put(HOST_NAME+'/survey/'+id,data,{headers});
  }
  getResponse()
  {
    const headers = new HttpHeaders().set(
      'Authorization',
      `Bearer ${localStorage.getItem('token')}`
    );
    return this.http.get(HOST_NAME+'/survey/',{headers});
  }
  getResponseDetail(_id)
  {
    const headers = new HttpHeaders().set(
      'Authorization',
      `Bearer ${localStorage.getItem('token')}`
    );
    return this.http.get(HOST_NAME+'/survey/'+_id,{headers});
  }

  postLinearOptions(details)
  {

    this.quesQuesOptions = details;
  }
  getLinearOptions()
  {

    return this.quesQuesOptions;
  }
  getTemplateName()
  {
    const headers = new HttpHeaders().set(
      'Authorization',
      `Bearer ${localStorage.getItem('token')}`
    );
    return this.http.get(HOST_NAME+'/patient/',{headers});
  }
  listProviderId()
  {
    const headers = new HttpHeaders().set(
      'Authorization',
      `Bearer ${localStorage.getItem('token')}`
    );
    return this.http.get(HOST_NAME+'/provider/',{headers});
  }
  getProviderById(id: string)
  {
    const headers = new HttpHeaders().set(
      'Authorization',
      `Bearer ${localStorage.getItem('token')}`
    );
    return this.http.get(HOST_NAME+'/provider/'+id,{headers});
  }
  getProviderByMail(providerEmail)
  {
    const headers = new HttpHeaders().set(
      'Authorization',
      `Bearer ${localStorage.getItem('token')}`
    );
    return this.http.get(HOST_NAME+'/provider/provider_info/'+providerEmail,{headers});
  }
  getPatientByMail(providerEmail)
  {
    const headers = new HttpHeaders().set(
      'Authorization',
      `Bearer ${localStorage.getItem('token')}`
    );
    return this.http.get(HOST_NAME+'/patient/patient_info/'+providerEmail,{headers});
  }
  deleteUsers()
  {
    const headers = new HttpHeaders().set(
      'Authorization',
      `Bearer ${localStorage.getItem('token')}`
    );
    return this.http.get(HOST_NAME+'/user_check/user_details_list/',{headers});
  }
  deleteUser(id)
  {
    const headers = new HttpHeaders().set(
      'Authorization',
      `Bearer ${localStorage.getItem('token')}`
    );
    return this.http.delete(HOST_NAME+'/user_check/'+id,{headers});
  }
  getPatients()
  {
    const headers = new HttpHeaders().set(
      'Authorization',
      `Bearer ${localStorage.getItem('token')}`
    );
    return this.http.get(HOST_NAME+'/patient/',{headers});
  }
  listen(): Observable<any>{
    return this._listeners.asObservable();
  }
  filter(){
    this._listeners.next();
  }
  phoneFormat(phno)
  {
    let newVal = phno.replace(/\D/g, '');
    if(newVal.length<=3)
    {
    }
    else
    {
      if (newVal.length === 0) {
        newVal = '';
      } else if (newVal.length >3 && newVal.length<=5) {
        newVal = newVal.replace(/^(\d{0,3})/, '($1)');
      } else if (newVal.length <= 6) {
        newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, '($1) $2');
      } else if (newVal.length <= 10) {
        newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3');
      } else {
        newVal = newVal.substring(0, 10);
        newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3');
      }
    }
    return newVal;
  }
  getPdf(surveyIid)
  {
    return this.http.get(HOST_NAME+'/user_check/generate_pdf?survey_id='+surveyIid);
  }
}
