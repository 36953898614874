/* eslint-disable no-underscore-dangle */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HOST_NAME } from 'src/environments/environment.local';
@Injectable({
  providedIn: 'root'
})
export class UserService {
  profileData = [];
  constructor(private http: HttpClient) { }
  fetchUsers() {
    const header = new HttpHeaders().set(
      'Authorization',
      `Bearer ${localStorage.getItem('token')}`
    );
    return this.http.get(HOST_NAME+'/patient/',{headers:header});
  }
  fetchProviders() {
    const header = new HttpHeaders().set(
      'Authorization',
      `Bearer ${localStorage.getItem('token')}`
    );
    return this.http.get(HOST_NAME+'/provider/',{headers:header});

  }
  fetchUser(userId)
  {
    const header = new HttpHeaders().set(
      'Authorization',
      `Bearer ${localStorage.getItem('token')}`
    );
    return this.http.get(HOST_NAME+'/patient/'+userId,{headers:header});
  }
  postUser(profileData)
  {
    const header = new HttpHeaders().set(
      'Authorization',
      `Bearer ${localStorage.getItem('token')}`
    );
    return this.http.post(HOST_NAME+'/patient/',profileData.value,{headers:header});


  }
  patchUser(profileForm)
  {
    const header = new HttpHeaders().set(
      'Authorization',
      `Bearer ${localStorage.getItem('token')}`
    );
    this.http.put(HOST_NAME+'/patient/'+profileForm._id,profileForm,{headers:header}).subscribe(data=>{
      // eslint-disable-next-line max-len
      fetch('https://wellcoursemetrics.azurewebsites.net/api/SyncPatientQuestionnaire?code=sEbgbMddTvEroD_ffSzxOuKMb4v8rk8zv180nhHGLs_tAzFuEf9Hpg==&patientId=' + profileForm._id);

    },error=>{});
  }

  patchUserSub(user) {
    const header = new HttpHeaders().set(
      'Authorization',
      `Bearer ${localStorage.getItem('token')}`
    );
    return this.http.put(HOST_NAME + '/patient/' +user._id, user, {headers:header});
  }

  fetchQuestionnaires()
  {
    const header = new HttpHeaders().set(
      'Authorization',
      `Bearer ${localStorage.getItem('token')}`
    );
    return this.http.get(HOST_NAME+'/questionnaire/',{headers:header});
  }

  postEmail(emailData)
  {
    const header = new HttpHeaders().set(
      'Authorization',
      `Bearer ${localStorage.getItem('token')}`
    );
    return this.http.post(HOST_NAME+'/email/',emailData,{headers:header});
  }
}
