// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false
};


export const INSERT_HOST_NAME = 'https://wellcoursemetrics.azurewebsites.net';
export const HOST_NAME = 'https://wellcourseappdev.azurewebsites.net';
export const REDIRECT_URL = 'http://localhost:8100/auth';

// LOCAL DEBUGGING
//export const HOST_NAME = 'http://127.0.0.1:8000';
