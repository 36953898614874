/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdminRoleGuard implements CanActivate {
  constructor(private router: Router){}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if(localStorage.getItem('user_type')==='Provider' || localStorage.getItem('user_type')==='Patient' || localStorage.getItem('user_type')==='Staff' || localStorage.getItem('user_type')==='Admin'||localStorage.getItem('user_type')==='ProviderStaff')
      {
        return true;
      }
      else{
        this.router.navigate(['auth/login']).then(() => {
          window.location.reload();
        });
        return false;
      }
  }
}
