import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import Player from '@vimeo/player';

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
})
export class VideoPlayerComponent implements OnInit, AfterViewInit {
  @Input() videoUrl: string;
  @ViewChild('vimeoPlayer') vimeoPlayer: ElementRef;

  constructor() {}

  ngAfterViewInit(): void {
    this.initializePlayer();
  }

  ngOnInit() {}

  initializePlayer() {
    new Player(this.vimeoPlayer.nativeElement, {
      url: this.videoUrl,
      width: '320',
      play_button_position: 'center',
      interactive_markers: false,
      byline: false
    });
  }
}
