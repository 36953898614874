import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HOST_NAME } from 'src/environments/environment.local';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  constructor(public http: HttpClient) {
  }

  fetchStaff(){
    const header = new HttpHeaders().set(
      'Authorization',
      `Bearer ${localStorage.getItem('token')}`
    );
    return this.http.get(HOST_NAME + '/staff/', { headers: header });
  }
  postStaff(form)
  {
    const header = new HttpHeaders().set(
      'Authorization',
      `Bearer ${localStorage.getItem('token')}`
    );
    return this.http.post(HOST_NAME+'/staff/',form,{headers:header});
  }
  fetchStaffID(id)
  {
    const header = new HttpHeaders().set(
      'Authorization',
      `Bearer ${localStorage.getItem('token')}`
    );
    return this.http.get(HOST_NAME+'/staff/'+id,{headers:header});
  }
  patchStaff(id,form)
  {
    const header = new HttpHeaders().set(
      'Authorization',
      `Bearer ${localStorage.getItem('token')}`
    );
    return this.http.put(HOST_NAME+'/staff/'+id,form,{headers:header});
  }
}
