import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VideoPlayerComponent } from './video-player/video-player.component';
import { ImageViewerComponent } from './image-viewer/image-viewer.component';
import { PdfViewerComponent } from './pdf-viewer/pdf-viewer.component';

@NgModule({
  declarations: [VideoPlayerComponent, ImageViewerComponent, PdfViewerComponent],
  imports: [
    CommonModule
  ],
  exports: [VideoPlayerComponent, ImageViewerComponent, PdfViewerComponent],
})
export class SharedModule {}
