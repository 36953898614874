import { Routes } from '@angular/router';
import { AdminRoleGuard } from './auth/guard/admin-role.guard';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full',
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthPageModule),
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardPageModule),
    canActivate: [AdminRoleGuard],
  },
  {
    path: 'chat',
    loadChildren: () => import('./chat/chat.module').then((m) => m.ChatPageModule),
    canActivate: [AdminRoleGuard],
  },
  {
    path: 'my-account',
    loadChildren: () => import('./my-account/my-account.module').then((m) => m.MyAccountPageModule),
    canActivate: [AdminRoleGuard],
  },
  {
    path: 'procedures',
    loadChildren: () => import('./procedures/procedures.module').then((m) => m.ProceduresPageModule),
    canActivate: [AdminRoleGuard],
  },
  {
    path: 'question-generate',
    loadChildren: () => import('./question-generate/question-generate.module').then((m) => m.QuestionGeneratePageModule),
    canActivate: [AdminRoleGuard],
  },
  {
    path: 'category1-provider',
    loadChildren: () => import('./category1-provider/category1-provider.module').then((m) => m.Category1PageModule),
    canActivate: [AdminRoleGuard],
  },
  {
    path: 'category1-staff',
    loadChildren: () => import('./category1-staff/category1-staff.module').then((m) => m.Category1StaffPageModule),
    canActivate: [AdminRoleGuard],
  },
  {
    path: 'browse',
    loadChildren: () => import('./browse/browse.module').then((m) => m.BrowsePageModule),
    canActivate: [AdminRoleGuard],
  },
  {
    path: 'survey-preview',
    loadChildren: () => import('./survey-preview/survey-preview.module').then((m) => m.SurveyPreviewPageModule),
    canActivate: [AdminRoleGuard],
  },
  {
    path: 'health-records',
    loadChildren: () => import('./health-records/health-records.module').then((m) => m.HealthRecordsPageModule),
    canActivate: [AdminRoleGuard],
  },
  {
    path: 'patients-procedures',
    loadChildren: () => import('./patients-procedures/patients-procedures.module').then((m) => m.PatientsProceduresPageModule),
    canActivate: [AdminRoleGuard],
  },
  {
    path: 'patients-records',
    loadChildren: () => import('./patients-records/patients-records.module').then((m) => m.PatientsRecordsPageModule),
    canActivate: [AdminRoleGuard],
  },
  {
    path: 'contact-us',
    loadChildren: () => import('./contact-us/contact-us.module').then((m) => m.ContactUsPageModule),
    canActivate: [AdminRoleGuard],
  },
  {
    path: 'about-us',
    loadChildren: () => import('./about-us/about-us.module').then((m) => m.AboutUsPageModule),
    canActivate: [AdminRoleGuard],
  },
  {
    path: 'metrics-generate',
    loadChildren: () => import('./metrics-generate/metrics-generate.module').then((m) => m.MetricsGeneratePageModule),
    canActivate: [AdminRoleGuard],
  },
  {
    path: 'provider-dashboard',
    loadChildren: () => import('./provider-dashboard/provider-dashboard.module').then((m) => m.ProviderDashboardPageModule),
    canActivate: [AdminRoleGuard],
  },
  {
    path: 'category1-admin',
    loadChildren: () => import('./category1-admin/category1-admin.module').then((m) => m.Category1AdminPageModule),
    canActivate: [AdminRoleGuard],
  },
  {
    path: 'ihealth-auth',
    loadChildren: () => import('./ihealth-auth/ihealth-auth.module').then((m) => m.IhealthAuthPageModule),
    canActivate: [AdminRoleGuard],
  },
  {
    path: '**',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthPageModule),
  },
];
