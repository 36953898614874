/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable no-underscore-dangle */
import { Component, OnDestroy, OnInit, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingController } from '@ionic/angular';
import { AppService } from './app.service';
import { SharedQuestionsService } from './shared/shared-questions.service';
import { AdminService } from './category1-admin/admin.service';
import { SharedBrowseService } from './browse/shared-browse.service';
import { Storage } from '@ionic/storage-angular';
import { UserService } from 'src/app/category1-provider/patient.service';
import { AuthService } from 'src/app/auth/auth.service';
import {
  listOfPendingQuestionnaireSchema,
  patientType,
  providerType,
  questionnaireType,
  staffType,
} from './question-generate/survey.model';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { EventEmitterService } from './shared/event-emitter.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  restrictTab: boolean;
  loading = true;
  loggedFlag = '';
  loggedEmail: string;
  loggedUserType = '';
  userProfile: Array<providerType>;
  user = { name: '', email: '', phoneNumber: '' };
  userData: Array<patientType>;
  questionnaireData: Array<listOfPendingQuestionnaireSchema> = [];
  providerList: Array<providerType>;
  providerId: providerType;
  responseQuestions: Array<questionnaireType>;
  patientIds: Array<patientType>;
  profilePhotoUrl = '';
  providersList: providerType;
  providersLists: Array<providerType>;
  isProvider = false;
  completeProviderList: Array<providerType>;
  patientList: Array<patientType>;
  questionList: Array<questionnaireType>;
  userStaff: Array<staffType>;
  internetConnectionFlag;
  providerStaff: Array<providerType> = [];
  questionnairesLength = 0;
  isRaniEnabled = false;
  public connected = new BehaviorSubject(window.navigator.onLine);
  constructor(
    private storage: Storage,
    private sharedBrowseService: SharedBrowseService,
    private adminService: AdminService,
    private sharedQuestions: SharedQuestionsService,
    public router: Router,
    private loadControl: LoadingController,
    private appser: AppService,
    private userService: UserService,
    private authService: AuthService,
    private eventEmitterService: EventEmitterService
  ) {
    window.addEventListener('online', (ev) => this.connected.next(true));
    window.addEventListener('offline', (ev) => this.connected.next(false));
  }

  ngOnInit() {
    this.connected.subscribe((connected) => (this.internetConnectionFlag = connected));
    this.sharedBrowseService.init();
    this.loggedEmail = this.authService.getLoggedFlag();
    if (this.appser.getUrl() === 'auth') {
      this.restrictTab = false;
    }

    this.eventEmitterService.onRaniEnabled.subscribe(() => {
      this.isRaniEnabled = true;
    });

    this.getPendingActionCount();
    if (localStorage.getItem('user_type') === 'Provider' && localStorage.getItem('user_type') !== 'Admin') {
      this.sharedQuestions.getProviderByMail(this.loggedFlag).subscribe((data) => {
        this.providersList = data as providerType;
        if (this.providersList.linked_provider !== null) {
          this.isProvider = false;
        } else {
          this.isProvider = true;
        }
      });
    }
    if (this.loggedUserType === 'Patient') {
      if (this.router.url === '/') {
        this.router.navigate(['/dashboard']);
      }
    }
    if (this.loggedUserType === 'Provider' || this.loggedUserType === 'ProviderStaff') {
      if (this.router.url === '/') {
        this.router.navigate(['/provider-dashboard']);
      }
    }
    if (this.loggedUserType === 'Staff') {
      // this.sharedQuestions.setQuestions();
      if (this.router.url === '/') {
        this.router.navigate(['/provider-dashboard']);
      }
    }
    if (this.loggedUserType === 'Admin') {
      // this.sharedQuestions.setQuestions();
      if (this.router.url === '/') {
        this.router.navigate(['/provider-dashboard']);
      }
    }
  }

  ionViewDidEnter() {}
  //If logged in as patient, will get a count of pending surveys need to be completed.
  //If logged in as provider, will get all the non reviewed responses count
  getPendingActionCount() {
    this.patientIds = [];
    this.loggedUserType = localStorage.getItem('user_type');
    this.loggedFlag = localStorage.getItem('user_email');
    if (this.loggedUserType === 'Patient') {
      // this.sharedQuestions.setQuestions();
      // this.sharedQuestions.setSurveys();
      this.loggedUserPatient();
    }
    if (this.loggedUserType === 'Provider' || this.loggedUserType === 'ProviderStaff') {
      this.sharedQuestions.setQuestions();
      this.loggedUserProviderOrProviderStaff();
    }
    if (this.loggedUserType === 'Staff') {
      this.loggedUserStaff();
    }
    if (this.profilePhotoUrl === '') {
      this.profilePhotoUrl = 'https://i.ibb.co/SXkLNNt/Deafult-Profile.png';
    }
  }
  ionViewWillEnter(loggedFlag) {
    this.loggedFlag = this.loggedFlag;
  }

  logOut() {
    localStorage.removeItem('user_type');
    localStorage.removeItem('user_email');
    localStorage.removeItem('token');
    localStorage.removeItem('practice_logo');
    localStorage.removeItem('profile_photo');
    localStorage.removeItem('signup_email');
    localStorage.removeItem(this.loggedFlag + 'recentSteps');
    localStorage.removeItem(this.loggedFlag + 'recentDistance');
    localStorage.removeItem(this.loggedFlag + 'recentSleep');
    localStorage.removeItem(this.loggedFlag + 'recentBodyTemperature');
    localStorage.removeItem(this.loggedFlag + 'recentfootTemperature');
    this.storage.remove('metric_surveys');
    this.storage.remove('editQuestionnaire');
    this.storage.remove('questionnaires');
    this.storage.remove('surveys');
    this.storage.remove('selected_patient_metric_surveys');
    this.storage.remove('selected_patient_email');
    this.storage.remove('selected_patient_id');
    this.storage.remove('selected_patient_sync_date');
    this.loggedUserType = '';
    this.loggedFlag = '';
    this.loadControl.create({ keyboardClose: true, message: 'Logging you out..' }).then((load) => {
      load.present();
      setTimeout(() => {
        load.dismiss();
        this.router.navigate(['auth']).then(() => {
          window.location.reload();
        });
      }, 1500);
    });
  }
  loggedUserPatient() {
    if (this.profilePhotoUrl === null || this.profilePhotoUrl === 'null') {
      this.profilePhotoUrl = 'https://i.ibb.co/SXkLNNt/Deafult-Profile.png';
      localStorage.setItem('profile_photo', this.profilePhotoUrl);
    }
    try {
      this.sharedQuestions.getTemplateName().subscribe(
        (response) => {
          this.userData = response as Array<patientType>;
          this.userData = this.userData.filter((data) => this.loggedFlag === data.email);
          try {
            this.questionnaireData = this.userData[0].pending_questionnaires as Array<listOfPendingQuestionnaireSchema>;
            this.questionnairesLength = this.questionnaireData.length;
            this.profilePhotoUrl = this.userData[0].profile_photo_url;
            if (this.profilePhotoUrl === null || this.profilePhotoUrl === 'null') {
              this.profilePhotoUrl = 'https://i.ibb.co/SXkLNNt/Deafult-Profile.png';
              localStorage.setItem('profile_photo', this.profilePhotoUrl);
            }
          } catch {
            this.questionnaireData = [];
          }
          localStorage.setItem('profile_photo', this.profilePhotoUrl);
          try {
            this.user.name = this.userData[0].patient_name;
            this.user.email = this.loggedFlag;
            this.user.phoneNumber = '9988661122';
          } catch {}
          // this.sharedBrowseService.setMetricSurveysPatient(this.loggedFlag+'local_metric_surveys',this.userData[0]['_id']);
        },
        (error) => {}
      );
    } catch {
      if (this.profilePhotoUrl === null || this.profilePhotoUrl === 'null' || this.profilePhotoUrl === '') {
        this.profilePhotoUrl = 'https://i.ibb.co/SXkLNNt/Deafult-Profile.png';
        localStorage.setItem('profile_photo', this.profilePhotoUrl);
      }
    }
  }
  loggedUserProviderOrProviderStaff() {
    forkJoin([this.sharedQuestions.listProviderId(), this.userService.fetchUsers()]).subscribe((usersList) => {
      this.userProfile = usersList[0] as Array<providerType>;
      this.patientList = usersList[1] as Array<patientType>;
      this.userProfile = this.userProfile.filter((data) => data.provider_email === this.loggedFlag);
      try {
        this.user.name = this.userProfile[0].provider_name;
        this.user.email = this.loggedFlag;
        this.user.phoneNumber = '9988661122';
      } catch {}
      this.profilePhotoUrl = this.userProfile[0].profile_photo_url;
      if (this.profilePhotoUrl === null || this.profilePhotoUrl === 'null') {
        this.profilePhotoUrl = 'https://i.ibb.co/SXkLNNt/Deafult-Profile.png';
      }
      localStorage.setItem('profile_photo', this.profilePhotoUrl);
      this.providerList = usersList[0] as Array<providerType>;
      this.providerStaff = usersList[0] as Array<providerType>;
      this.completeProviderList = usersList[0] as Array<providerType>;
      this.providerList = this.providerList.filter((data) => data.provider_email === this.loggedFlag);
      this.providerId = this.providerList[0] as providerType;
      this.sharedQuestions.getResponse().subscribe(
        (data) => {
          this.responseQuestions = data as Array<questionnaireType>;
          if (this.loggedUserType === 'Provider') {
            this.providerStaff = this.providerStaff.filter((staffDetails) => this.loggedFlag === staffDetails['linked_provider']);
            const tempStaffList: any = [];
            for (const staffDetail of this.providerStaff) {
              tempStaffList.push(staffDetail['_id']);
            }
            this.responseQuestions = this.responseQuestions.filter((responseQuestionnaire) => {
              try {
                return (
                  (responseQuestionnaire.provider_id === this.providerId._id ||
                    tempStaffList.includes(responseQuestionnaire.provider_id)) &&
                  responseQuestionnaire['status'] === 'Not Reviewed'
                );
              } catch {}
            });
          } else {
            this.providersLists = this.completeProviderList.filter(
              (response2) => response2.provider_email === this.providerId['linked_provider']
            );
            this.providerStaff = this.providerStaff.filter(
              (staffDetails) => this.providersLists[0].provider_email === staffDetails['linked_provider']
            );
            const tempStaffList: any = [];
            for (const staffDetail of this.providerStaff) {
              tempStaffList.push(staffDetail['_id']);
            }
            this.responseQuestions = this.responseQuestions.filter((responseQuestionnaire) => {
              try {
                return (
                  (responseQuestionnaire.provider_id === this.providersLists[0]._id ||
                    tempStaffList.includes(responseQuestionnaire.provider_id)) &&
                  responseQuestionnaire['status'] === 'Not Reviewed'
                );
              } catch {}
            });
          }

          for (const responseQues of this.responseQuestions) {
            if (!this.patientIds.includes(responseQues['patient_name'])) {
              this.patientIds.push(responseQues['patient_name']);
            }
          }
        },
        (error) => {}
      );
      // });
    });
  }
  loggedUserStaff() {
    this.adminService.fetchStaff().subscribe((response) => {
      this.userStaff = response as Array<staffType>;
      this.userStaff = this.userStaff.filter((data) => data.staff_email === this.loggedFlag);
      this.user.name = this.userStaff[0].staff_name;
      this.user.email = this.loggedFlag;
      this.user.phoneNumber = this.userStaff[0].phone_number;
    });
  }
  reloadApp() {
    window.location.reload();
  }
}
