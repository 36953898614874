/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/prefer-for-of */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/dot-notation */
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { HOST_NAME } from 'src/environments/environment.local';
import * as cordovaSQLiteDriver from 'localforage-cordovasqlitedriver';
import { LoadingController } from '@ionic/angular';
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';
import { metricSurveyType } from '../question-generate/survey.model';
import { HttpErrorResponse } from '@angular/common/http';
import { EventEmitterService } from '../shared/event-emitter.service';

@Injectable({
  providedIn: 'root',
})
export class SharedBrowseService {
  dayFlag = false;
  monthFlag = false;
  backendStepArray: Array<metricSurveyType> = [];
  backendStepArrayPatch: Array<metricSurveyType> = [];
  insertFlagDaily = true;
  insertFlagWeekly = true;
  insertFlagMonthly = true;
  loggedFlag = '';
  noOfWeeks;
  firstday;
  lastday;
  constructor(
    private http: HttpClient,
    private storage: Storage,
    private loadController: LoadingController,
    private authService: AuthService,
    private router: Router,
    private eventEmitterService: EventEmitterService
  ) {}
  async init() {
    await this.storage.defineDriver(cordovaSQLiteDriver);
    await this.storage.create();
  }
  postMetricSurveys(form) {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${localStorage.getItem('token')}`);
    this.storage.get('metric_surveys').then((response) => {
      if (response == null) {
        this.storage.set('metric_surveys', [form]);
        return;
      } else {
        const items: any = response;
        items.push(form);
        this.storage.set('metric_surveys', items);
        return;
      }
    });
    return this.http.post(HOST_NAME + '/metric_surveys/', form, { headers });
  }
  getMetricSurveys() {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${localStorage.getItem('token')}`);
    return this.http.get(HOST_NAME + '/metric_surveys/', { headers });
  }
  getMetricSurveysByPatientId(id) {
    const url = 'https://wellcoursemetrics.azurewebsites.net/api/GetMetricSurveysByPatientId?code=096FcemmZODjKWSIJ2O2xu5R48oMjohwmu3TdBCdWlmSAzFu2EhJhQ==&patient_id=' + id;
    return this.http.get(url);
  }
  deleteMetricSurveys(id) {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${localStorage.getItem('token')}`);
    return this.http.delete(HOST_NAME + '/metric_surveys/' + id, { headers });
  }
  setMetricSurveysProvider(queryParams) {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${localStorage.getItem('token')}`);
    // this.http.get(HOST_NAME + '/metric_surveys/', { headers }).subscribe(response => {
    //   this.storage.set(storedName, response);
    // });
    let params = new HttpParams().set('patient_id', queryParams.patient_id);
    if (queryParams.metric_type !== undefined) {
      params = params.set('metric_type', queryParams.metric_type);
    }
    if (queryParams.aggregation_type !== undefined) {
      params = params.set('aggregation_type', queryParams.aggregation_type);
    }
    if (queryParams.aggregation_from_value !== undefined) {
      params = params.set('aggregation_from_value', queryParams.aggregation_from_value);
    }
    if (queryParams.aggregation_to_value !== undefined) {
      params = params.set('aggregation_to_value', queryParams.aggregation_to_value);
    }
    return this.http.get(HOST_NAME + '/metric_surveys/metric_surveys/', { headers, params });
  }
  setMetricSurveysPatient(storedName, patient_id) {
    const params = new HttpParams().set('patient_id', patient_id);
    const headers = new HttpHeaders().set('Authorization', `Bearer ${localStorage.getItem('token')}`);
    this.http.get(HOST_NAME + '/metric_surveys/metric_surveys/', { headers, params }).subscribe((response) => {
      // eslint-disable-next-line prefer-const
      let metricResponse = [];
      // response = (response as Array<metricSurveyType>).filter(metricData=>metricData.patient_id===patient_id);
      for (let resp = 0; resp < (response as Array<metricSurveyType>).length; resp++) {
        if ((response as Array<metricSurveyType>)[resp].patient_id === patient_id) {
          metricResponse.push((response as Array<metricSurveyType>)[resp]);
        }
        if ((response as Array<metricSurveyType>).length > 0 && resp + 1 === (response as Array<metricSurveyType>).length) {
          this.storage.set(storedName, metricResponse);
        }
      }
    });
  }
  patchMetricSurveys(queryParams, metricForm) {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${localStorage.getItem('token')}`);
    const params = new HttpParams();
    params['patient_id'] = queryParams.patient_id;
    params['metric_type'] = queryParams.metric_type;
    params['aggregation_type'] = queryParams.aggregation_type;
    params['aggregation_from_value'] = queryParams.aggregation_from_value;
    params['aggregation_to_value'] = queryParams.aggregation_to_value;
    return this.http.put(
      HOST_NAME +
        '/metric_surveys/metric_agg_update/' +
        '?patient_id=' +
        queryParams.patient_id +
        '&metric_type=' +
        queryParams.metric_type +
        '&aggregation_type=' +
        queryParams.aggregation_type +
        '&aggregation_from_value=' +
        queryParams.aggregation_from_value +
        '&aggregation_to_value=' +
        queryParams.aggregation_to_value,
      metricForm,
      { headers }
    );
  }

  getWeeksInMonth(year, month) {
    const weeks = [];
    const firstDate = new Date(year, month, 1);
    const lastDate = new Date(year, month + 1, 0);
    const numDays = lastDate.getDate();

    let dayOfWeekCounter = firstDate.getDay();

    for (let date = 1; date <= numDays; date++) {
      if (dayOfWeekCounter === 0 || weeks.length === 0) {
        weeks.push([]);
      }
      weeks[weeks.length - 1].push(date);
      dayOfWeekCounter = (dayOfWeekCounter + 1) % 7;
    }

    return weeks
      .filter((w) => !!w.length)
      .map((w) => ({
        start: w[0],
        end: w[w.length - 1],
        dates: w,
      }));
  }

  setAggregations(response, metricType, metricTime, metricValue, routerNavigation, backendStepArray, backendStepArrayPatch, patientId) {
    this.loggedFlag = this.authService.getLoggedFlag();
    this.backendStepArray = backendStepArray;
    this.backendStepArrayPatch = backendStepArrayPatch;
    this.insertFlagDaily = true;
    this.insertFlagWeekly = true;
    this.insertFlagMonthly = true;
    response = this.updateAggregatedRecord(response, metricType, metricTime, metricValue);
    response = this.insertNewAggregatedRecord(response, metricType, metricTime, metricValue, patientId);
    this.storage.set(this.loggedFlag + 'local_metric_surveys', response).then(() => {
      this.loadController.create({ keyboardClose: true, message: 'Adding the data..' }).then((load) => {
        load.present();
        setTimeout(() => {
          if (this.backendStepArray.length > 0) {
            this.postMetricSurveys(this.backendStepArray).subscribe((data1) => {
              this.eventEmitterService.healthKitSyncComplete();
            });
          }
          if (this.backendStepArrayPatch.length > 0) {
            for (const steps of this.backendStepArrayPatch) {
              const aggFrom = new Date(steps.aggregation_from_value).toISOString();
              const aggTo = new Date(steps.aggregation_to_value).toISOString();
              this.patchMetricSurveys(
                {
                  patient_id: steps.patient_id,
                  metric_type: steps.metric_type,
                  aggregation_type: steps.aggregation_type,
                  aggregation_from_value: aggFrom,
                  aggregation_to_value: aggTo,
                },
                steps
              ).subscribe((data1) => {});
            }
          }
          this.router.navigate([routerNavigation]);
          load.dismiss();
        }, 1000);
      });
    });
  }
  updateAggregatedRecord(response, metricType, metricTime, metricValue) {
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let agg = 0; agg < response.length; agg++) {
      if (
        response[agg].aggregation_type === 'Daily' &&
        response[agg].metric_type === metricType &&
        new Date(metricTime).getDate() === new Date(response[agg].aggregation_from_value).getDate() &&
        new Date(metricTime).getMonth() === new Date(response[agg].aggregation_from_value).getMonth() &&
        new Date(metricTime).getFullYear() === new Date(response[agg].aggregation_from_value).getFullYear()
      ) {
        if (metricType === 'BodyTemperature' || metricTime === 'footTemperature') {
          response[agg].total = Number(metricValue);
        } else {
          response[agg].total += Number(metricValue);
        }
        this.backendStepArrayPatch.push(response[agg]);
        this.insertFlagDaily = false;
      }
      if (response[agg].aggregation_type === 'Weekly' && response[agg].metric_type === metricType) {
        if (
          new Date(metricTime).getMonth() === new Date(response[agg].aggregation_from_value).getMonth() &&
          new Date(metricTime).getFullYear() === new Date(response[agg].aggregation_from_value).getFullYear() &&
          new Date(response[agg].aggregation_from_value).getDate() >= new Date(metricTime).getDate() &&
          new Date(response[agg].aggregation_to_value).getDate() <= new Date(metricTime).getDate()
        ) {
          if (metricType === 'BodyTemperature' || metricTime === 'footTemperature') {
            response[agg].total = Number(metricValue);
          } else {
            response[agg].total += Number(metricValue);
          }
          this.backendStepArrayPatch.push(response[agg]);
          this.insertFlagWeekly = false;
        }
      }
      if (
        response[agg].aggregation_type === 'Monthly' &&
        response[agg].metric_type === metricType &&
        new Date(metricTime).getMonth() === new Date(response[agg].aggregation_from_value).getMonth() &&
        new Date(metricTime).getFullYear() === new Date(response[agg].aggregation_from_value).getFullYear()
      ) {
        if (metricType === 'BodyTemperature' || metricTime === 'footTemperature') {
          response[agg].total = Number(metricValue);
        } else {
          response[agg].total += Number(metricValue);
        }
        this.backendStepArrayPatch.push(response[agg]);
        this.insertFlagMonthly = false;
      }
    }
    return response;
  }
  insertNewAggregatedRecord(response, metricType, metricTime, metricValue, patientId) {
    this.noOfWeeks = this.getWeeksInMonth(new Date(metricTime).getFullYear(), new Date(metricTime).getMonth());
    if (this.insertFlagDaily === true) {
      const temp = {
        patient_id: patientId,
        metric_type: metricType,
        aggregation_type: 'Daily',
        aggregation_from_value: new Date(metricTime).toISOString(),
        aggregation_to_value: new Date(metricTime).toISOString(),
        date_type: 0,
        total: Number(metricValue),
        source: 'Manual',
        entries: [],
      };
      response.push(temp);
      this.backendStepArray.push(temp as metricSurveyType);
    }
    if (this.insertFlagWeekly === true) {
      for (const no of this.noOfWeeks) {
        if (new Date(metricTime).getDate() >= no.start && new Date(metricTime).getDate() <= no.end) {
          if (no.start >= 0 && no.start < 10) {
            no.start = '0' + no.start;
          }
          if (no.end >= 0 && no.end < 10) {
            no.end = '0' + no.end;
          }
          const month = new Date(metricTime).getMonth() + 1;
          if (month >= 1 && month <= 9) {
            const start = new Date(metricTime).getFullYear() + '-' + '0' + month + '-' + no.start;
            const end = new Date(metricTime).getFullYear() + '-' + '0' + month + '-' + no.end;
            this.firstday = new Date(start);
            this.lastday = new Date(end);
          } else {
            const start = new Date(metricTime).getFullYear() + '-' + month + '-' + no.start;
            const end = new Date(metricTime).getFullYear() + '-' + month + '-' + no.end;
            this.firstday = new Date(start);
            this.lastday = new Date(end);
          }
        }
      }
      const temp = {
        patient_id: patientId,
        metric_type: metricType,
        aggregation_type: 'Weekly',
        aggregation_from_value: new Date(this.firstday).toISOString(),
        aggregation_to_value: new Date(this.lastday).toISOString(),
        date_type: 0,
        total: Number(metricValue),
        source: 'Manual',
        entries: [],
      };
      response.push(temp);
      this.backendStepArray.push(temp as metricSurveyType);
    }
    if (this.insertFlagMonthly === true) {
      const temp = {
        patient_id: patientId,
        metric_type: metricType,
        aggregation_type: 'Monthly',
        aggregation_from_value: new Date(metricTime).toISOString(),
        aggregation_to_value: new Date(metricTime).toISOString(),
        date_type: 0,
        total: Number(metricValue),
        source: 'Manual',
        entries: [],
      };

      response.push(temp);
      this.backendStepArray.push(temp as metricSurveyType);
    }
    return response;
  }
  getFirstAndLastDayOfWeek(currentDate) {
    let firstday = new Date(currentDate.setDate(currentDate.getDate() - currentDate.getDay()));
    firstday = new Date(firstday.setHours(0));
    firstday = new Date(firstday.setMinutes(0));
    firstday = new Date(firstday.setSeconds(0));
    firstday = new Date(firstday.setMilliseconds(0));
    let lastday = new Date(currentDate.setDate(currentDate.getDate() - currentDate.getDay() + 6));
    lastday = new Date(lastday.setHours(24));
    lastday = new Date(lastday.setMinutes(0));
    lastday = new Date(lastday.setSeconds(0));
    lastday = new Date(lastday.setMilliseconds(0));
    return [firstday, lastday];
  }
}
