<div class="d-flex">
  <div class="close-button-div">
    <button class="close-button btn btn-outline-primary" (click)="confirm()">Close</button>
    <button class="close-button p-2" (click)="increaseWidth()" style="font-size: x-large">+</button>
    <button class="close-button p-2" (click)="decreaseWidth()" style="font-size: x-large">-</button>
  </div>
  <div class="image-div">
    <img #image class="image" [src]="imageUrl" alt="image" style="max-width: 300%;" />
  </div>
</div>
