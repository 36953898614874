import { Component, Input, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.scss'],
})
export class ImageViewerComponent implements OnInit, AfterViewInit {

  @ViewChild('image') img: ElementRef<HTMLImageElement>;
  @Input() imageUrl: string;

  width = 100;

  constructor(private modal: ModalController) {}

  ngAfterViewInit(): void {
    this.width = 100;
    this.img.nativeElement.style.width = `${this.width}%`;
  }

  ngOnInit() {}

  increaseWidth() {
    this.img.nativeElement.style.width = `${(this.width += 25)}%`;
  }

  decreaseWidth() {
    this.img.nativeElement.style.width = `${(this.width -= 25)}%`;
  }

  confirm() {
    this.modal.dismiss('confirm');
  }
}
